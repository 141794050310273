import { SdkConfig } from '@criptalia/sdk/src/Sdk'

const wsProtocol = import.meta.env.VITE_API_URL.search(/^https:/) > -1 ? 'wss://' : 'ws://'

const websocketUrl = wsProtocol + import.meta.env.VITE_API_DOMAIN + '/connection/websocket'

const isProduction = window.location.host.endsWith('vivacrowd.it')
export const FRONTEND_ENV: SdkConfig['env'] = {
  isProduction,
  isProductionBuild: import.meta.env.PROD,
  websocketUrl,
  brandName: 'vivacrowd',
  datocmsToken:
    import.meta.env.VITE_DATO_CMS_TOKEN || 'e25973311b1bc9c801846106a5b9f1',
  mapsApiKey:
    import.meta.env.VITE_MAPS_API_KEY ||
    'AIzaSyB15ds5CBvNKu8VGdxMxKp7nVPPLYtqQkM',    
}
